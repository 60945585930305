import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/users/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/users/own',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(newPassword, token, userid) {
    return ApiService.fetchData({
        headers: {
            'Authorization': 'Bearer ' + token
        },
        url: `/users/${userid}`,
        method: 'put',
        data: {
            password: newPassword,
        }
    })
}

export async function apiForgotPassword(email, org) {
    return ApiService.fetchData({
        url: `/users/reset/password`,
        method: 'post',
        data: {
            email: email,
            organization: org
        },
    })
}

export async function apiGetCode(email, org, code) {
    return ApiService.fetchData({
        url: `/users/reset/password?email=${email}&organization=${org}&secret=${code}`,
        method: 'get',
    })
}
