import React, { useEffect, useState } from 'react'

import CreatableSelect from 'react-select/creatable'
import { selectCustomStyle } from './FromikSelect'
import { apiGetCategory } from 'services/DcdService'
import { useSelector } from 'react-redux'

const createOption = (label, value) => ({ label, value })

const CategorySelect = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([]);

  const categories = useSelector(state => state.dcd.categories)

  const getCategoryName = (categoryId) => {
    if(categoryId && isNaN(categoryId)) return ''

    const category = categories.find(cat => cat.id === categoryId)
    return (category && category.name) || ''
  }

  const  onChange = (newOption) => {
    props.form.setFieldValue(props.field.name, newOption?.value)
    props.form.setFieldValue('category', getCategoryName(newOption?.value))
  }

  const handleCreate = async (code) => {
    setIsLoading(true)
    const newOption = createOption(code, code)
    setIsLoading(false)
    setOptions((prev) => [...prev, newOption])
    onChange({value: code})
  }

  useEffect(() => {
    async function getCategory() {
      let categories = await apiGetCategory()
      categories = categories?.data?.filter(item => item?.code?.trim() !== '').map((category) =>
        createOption(category.code, category.id)
      )
      setOptions(categories)
    }
    getCategory()
  }, [])

  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onCreateOption={handleCreate}
      options={options}
      styles={selectCustomStyle}
      value={(options ? options.find(option => option.value === props.field.value) : '')}
      onChange={onChange}
      classNamePrefix='select-field'
    />
  )
}

export default CategorySelect
