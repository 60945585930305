import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import fr from './lang/fr.json'
import nl from './lang/nl.json'
import pt from './lang/pt.json'
import ar from './lang/ar.json'
import hi from './lang/hi.json'
import appConfig from 'configs/app.config'

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
  pt: {
    translation: pt,
  },
  ar: {
    translation: ar,
  },
  hi: {
    translation: hi,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: appConfig.locale,
  lng: appConfig.locale,
  interpolation: {
    escapeValue: false,
  },
})

export const dateLocales = {
  en: () => import('dayjs/locale/en'),
}

export default i18n
