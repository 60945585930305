import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NAV_ITEM_TYPE_ITEM } from 'constants/navigation.constant'
import { apiGetStandardTypes } from 'services/DcdService'

export const initialState = {
    currentRouteKey: '',
    standardTypes: []
}

export const getStandardTypes = createAsyncThunk('standardTypes/getList', async () => {
    const res = await apiGetStandardTypes()
    return res.data.map((type) => ({
        id: type.id,
        key: `collections/${type.id}`,
        path: `collection/${ type.id }`,
        title: type.name,
        icon: 'odk',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    }))
})

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
    },
    extraReducers: {
        [getStandardTypes.fulfilled]: (state, action) => {
            state.standardTypes = action.payload
        },
    },
})

export const { setCurrentRouteKey } = commonSlice.actions

export default commonSlice.reducer
