import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { showError } from 'utils/triggerNotification'

const unauthorizedCode = [401]

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = sessionStorage.getItem('token')
    //const persistData = deepParseJson(rawPersistData)

    //let accessToken = persistData.auth.session.token
    let accessToken = rawPersistData

    // if (!accessToken) {
    //   const { auth } = store.getState()
    //   accessToken = auth.session.token
    // }

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

BaseService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error

    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
      showError('Unauthorised! Please login again.')
    }

    return Promise.reject(error)
  }
)

export default BaseService
