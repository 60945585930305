import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  avatar: '',
  userName: '',
  email: '',
  authority: [],
}

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
    updateUserStore: (state, action) => {
      state.address = action.payload.address;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
    },
  },
})

export const { setUser, updateUserStore } = userSlice.actions

export default userSlice.reducer
