import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appConfig from 'configs/app.config'
import { apiGetLanguages } from 'services/LangService'

const initialState = {
    currentLang: appConfig.locale,
    loading: false,
    data: []
}

export const getLanguages = createAsyncThunk('locale/getList', async () => {
    const response = await apiGetLanguages()
    const languagesMap = {};
    for(const lang of response.data) {
        languagesMap[lang.code] = lang
    }
    return languagesMap
})

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLang: (state, action) => {
            state.currentLang = action.payload
        },
    },
    extraReducers: {
        [getLanguages.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [getLanguages.pending]: (state) => {
            state.loading = true
        },
    }
})

export const { setLang } = localeSlice.actions

export default localeSlice.reducer
