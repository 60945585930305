import React, { useEffect, useState } from 'react'

import CreatableSelect from 'react-select/creatable'
import { selectCustomStyle } from './FromikSelect'
import { apiGetDcdsByCategory } from 'services/DcdService'

const createOption = (label, value) => ({ label, value })

const DcdSelect = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([]);
  const [formByCategory, setFormByCategory] = useState([]);

  const getVersion = (formId) => {
    if(formId && isNaN(formId)) return '1'
  
    const form = formByCategory.find(form => form.id === formId)
    return form ? String(Number(form.version) + 1) : '1'
  }

  const getFormName = (formId) => {
    if(formId && isNaN(formId)) return ''
  
    const form = formByCategory.find(form => form.id === formId)
    return (form && form.name) || ''
  }

  const onChange = (newOption) => {
    props.form.setFieldValue(props.field.name, newOption?.value)
    props.form.setFieldValue('form', getFormName(newOption?.value))
    props.setVersion(getVersion(newOption?.value))
  }

  const handleCreate = async (code) => {
    setIsLoading(true)
    const newOption = createOption(code, code);
    setIsLoading(false)
    setOptions((prev) => [...prev, newOption])
    onChange({value: code})
  }

  useEffect(() => {
    async function getDcdByCategory() {
      if(props.data.categoryCode && !isNaN(props.data.categoryCode)) {
        let dcds = await apiGetDcdsByCategory(props.data.categoryCode)
        setFormByCategory(dcds?.data)
        props.setFormByCategory(dcds?.data)
        dcds = dcds?.data?.filter(item => item?.code?.trim() !== '').map((dcd) =>
            createOption(dcd.code, dcd.id)
        )
        setOptions(dcds)
      }
    }
    getDcdByCategory()
  }, [props.data.categoryCode])

  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading || !(props.data.category && props.data.categoryCode)}
      isLoading={isLoading}
      onCreateOption={handleCreate}
      options={options}
      styles={selectCustomStyle}
      value={(options ? options.find(option => option.value === props.field.value) : '')}
      onChange={onChange}
      classNamePrefix='select-field'
    />
  )
}

export default DcdSelect
