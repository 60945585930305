import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetCategory,
    apiGetDcdList,
    apiGetPublishedAccessibleUserForms
} from 'services/DcdService'

// export const getFormsList = createAsyncThunk('dcdList/getList', async () => {
//     const response = await apiGetDcdList()
//     return response?.data?.list
// })

export const getCategoryList = createAsyncThunk('dcdList/getCategories', async () => {
    const response = await apiGetCategory()
    return response.data
})

export const getPublishedAccessibleForms = createAsyncThunk('dcdList/getAccessibleForms', async () => {
    const response = await apiGetPublishedAccessibleUserForms('')
    return response?.data?.list
})

// export const putProject = createAsyncThunk(
//     'dcdList/putProject',
//     async (data) => {
//         const response = await apiPutProjectList(data)
//         return response.data
//     }
// )

const dcdSlice = createSlice({
    name: 'dcdList/data',
    initialState: {
        loading: false,
        dcdList: [],
        allMembers: [],
        view: 'grid',
        query: {
            order: 'asc',
            search: '',
        },
        categories: [],
        dcForms: []
    },
    reducers: {
        toggleView: (state, action) => {
            state.view = action.payload
        },
        toggleSort: (state, action) => {
            state.query.order = action.payload
        },
        setSearch: (state, action) => {
            state.query.search = action.payload
        },
        addDcd: (state, action) => {
            state.dcdList = [ ...state.dcdList, action.payload ]
        },
        addCategory: (state, action) => {
            state.categories = [ ...state.categories, action.payload]
        }
    },
    extraReducers: {
        [getCategoryList.fulfilled]: (state, action) => {
            state.categories = action.payload
            state.loading = false
        },
        [getPublishedAccessibleForms.fulfilled]: (state, action) => {
            state.dcForms = action.payload
        },
        [getCategoryList.pending]: (state) => {
            state.loading = true
        }
    },
})

export const { toggleView, toggleSort, setSearch, addDcd, addCategory } = dcdSlice.actions

export default dcdSlice.reducer
