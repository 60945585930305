import ReactSelect from 'react-select'
import React from 'react'
import { useField } from 'formik'

export const selectCustomStyle = {
  control: (base) => ({
    ...base,
    height: 44,
    minHeight: 44,
  }),
}

function FromikSelect(props) {
  const [, , { setValue, setTouched }] = useField(props.field.name)

  const onChange = (data) => {
    const value = props.isMulti ? data.map(item => item.value).join(',') : data?.value || null
    setValue(value)
    props.form.setFieldValue(props.field.name, data)
  }


  return (
    <ReactSelect
      isClearable
      isDisabled={props.disabled}
      value={props.field.value}
      styles={selectCustomStyle}
      {...props}
      onChange={onChange}
      onBlur={setTouched}
    />
  )
}

export default FromikSelect
