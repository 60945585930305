import ApiService from './ApiService'

export async function apiGetCategory() {
  return ApiService.fetchData({
    url: 'categories',
    method: 'get',
  })
}

export async function apiGetStandardTypes() {
  return ApiService.fetchData({
    url: 'standardTypes',
    method: 'get',
  })
}

export async function apiPostStandardTypes(data) {
  return ApiService.fetchData({
    url: 'standardTypes',
    method: 'post',
    data,
  })
}

export async function apiPostCategory(data) {
  return ApiService.fetchData({
    url: 'categories',
    method: 'post',
    data,
  })
}

export async function apiPostDcd(data) {
  return ApiService.fetchData({
    url: 'forms',
    method: 'post',
    data,
  })
}

export async function apiPutDcd(id, data) {
  return ApiService.fetchData({
    url: `forms/${id}`,
    method: 'put',
    data,
  })
}

export async function apiGetDcdList() {
  return ApiService.fetchData({
    url: 'forms',
    method: 'get',
  })
}

export async function apiGetPublishedAccessibleUserForms(params='') {
  return ApiService.fetchData({
    url: `forms/user${params}`,
    method: 'get',
  })
}

export async function apiGetPublishedOwnerForms(params='') {
  return ApiService.fetchData({
    url: `forms/owner${params}`,
    method: 'get',
  })
}

export async function apiGetPublishedAccessibleOrgForms(params='') {
  return ApiService.fetchData({
    url: `forms/organization${params}`,
    method: 'get',
  })
}

export async function apiGetDcdsByCategory(categoryId) {
  return ApiService.fetchData({
    url: `forms/category/${categoryId}`,
    method: 'get',
  })
}

export async function apiPostCodelist(id, data) {
  return ApiService.fetchData({
    url: `codelists/batch/form/${id}`,
    method: 'post',
    data,
  })
}

export async function apiPostTranslation(id, data) {
  return ApiService.fetchData({
    url: `translations/batch/form/${id}`,
    method: 'post',
    data,
  })
}

export async function apiPostField(id, data) {
  return ApiService.fetchData({
    url: `formFields/upload/${id}`,
    method: 'post',
    data,
  })
}

export async function apiGetForm(id) {
  return ApiService.fetchData({
    url: `formFields/form/${id}`,
    method: 'get',
  })
}

export async function apiGetSteps(id) {
  return ApiService.fetchData({
    url: `forms/upload/${id}`,
    method: 'get',
  })
}

export async function apiPostSteps(id) {
  return ApiService.fetchData({
    url: `forms/upload/${id}`,
    method: 'post',
  })
}

export async function apiPutSteps(id, data) {
  return ApiService.fetchData({
    url: `forms/upload/${id}`,
    method: 'put',
    data,
  })
}

export async function apiGetSubmissions(id, data) {
  return ApiService.fetchData({
    url: `submissions/form/${id}${data}`,
    method: 'get',
  })
}

export async function apiPostSubmission(id, data) {
  return ApiService.fetchData({
    url: `submissions/form/${id}`,
    method: 'post',
    data,
  })
}
export async function apiPutSubmission(id, data) {
  return ApiService.fetchData({
    url: `submissions/${id}`,
    method: 'put',
    data,
  })
}

export async function apiGetSubmission(id) {
  return ApiService.fetchData({
    url: `submissions/${id}`,
    method: 'get',
  })
}

export async function apiPostCSV(id, data) {
  const formData = new FormData()
  formData.append('file', data)
  return ApiService.fetchData({
    url: `submissions/csv/form/${id}`,
    method: 'post',
    data: formData,
  })
}

export async function apiDeleteSubmission(id) {
  return ApiService.fetchData({
    url: `submissions/${id}`,
    method: 'delete'
  })
}

export async function apiApproveSubmissions(ids) {
  return ApiService.fetchData({
      url: `submissions/approve`,
      method: 'put',
      data: { ids }
  })
}

export async function apiGetFroms(params) {
  return ApiService.fetchData({
    url: `forms${params}`,
    method: 'get',
  })
}

export async function apiPostODKXlsValidate(data) {
  const formData = new FormData()
  formData.append('file', data)
  return ApiService.fetchData({
    url: `forms/odkValidate`,
    method: 'post',
    data: formData,
  })
}
