import { toast, Notification } from 'components/ui'

const showSuccess = (title, msg) => {
  toast.push(
    <Notification title={title} type="success">
      {msg}
    </Notification>
  )
}

const showError = (title, msg) => {
  toast.push(
    <Notification title={title} type="danger">
      {msg || 'Something went wrong, Please try again!' }
    </Notification>
  )
}

export { showSuccess, showError }
